import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, mergeProps as _mergeProps, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_2 = {
    key: 1
};
const _hoisted_4 = {
    key: 1
};
import { IconName } from '@leon-hub/icons';
import { ButtonKind } from '@leon-hub/module-buttons';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useCustomerNotificationDetailsRoutePage } from 'web/src/modules/customer-notifications/pages/CustomerNotificationDetailsRoutePage/composables';
import CustomerRefNotification from 'web/src/modules/customer-notifications/components/CustomerRefNotification/CustomerRefNotification.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerNotificationDetailsRoutePage',
    setup (__props) {
        const { message, messageTime, ctaButtonProperties, isNotRefMessage, onCtaClick, chatButtonProperties, openChat } = useCustomerNotificationDetailsRoutePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['notification-details'])
            }, [
                _unref(message) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['notification-details__title'])
                        }, _toDisplayString(_unref(message).title), 3),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['notification-details__time'])
                        }, _toDisplayString(_unref(messageTime)), 3)
                    ])),
                    _unref(isNotRefMessage) ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: _normalizeClass(_ctx.$style['notification-details__message'])
                    }, [
                        _createVNode(VDynamicContent, {
                            content: _unref(message)?.message,
                            class: _normalizeClass(_ctx.$style['notification-details__message-text'])
                        }, null, 8, [
                            "content",
                            "class"
                        ]),
                        (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _unref(message)?.button ? (_openBlock(), _createBlock(VButton, _mergeProps({
                                key: 0
                            }, _unref(ctaButtonProperties), {
                                onClick: _unref(onCtaClick)
                            }), null, 16, [
                                "onClick"
                            ])) : (_openBlock(), _createBlock(VButton, _mergeProps({
                                key: 1
                            }, _unref(chatButtonProperties), {
                                onClick: _unref(openChat)
                            }), null, 16, [
                                "onClick"
                            ]))
                        ]))
                    ], 2)) : (_openBlock(), _createBlock(CustomerRefNotification, {
                        key: 3,
                        data: _unref(message)
                    }, null, 8, [
                        "data"
                    ]))
                ], 64)) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                }))
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomerNotificationDetailsRoutePage'
                ]
            ]);
        };
    }
});
