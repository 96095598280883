import { computed, onBeforeUnmount, onMounted, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { IconName } from '@leon-hub/icons';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { useCustomerNotificationsStore } from 'web/src/modules/customer-notifications/store';
import { getMessageByIdAndSource } from 'web/src/modules/customer-notifications/store/utils';
import { CustomerNotificationMessageSource } from 'web/src/modules/customer-notifications/enums';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useCustomerNotificationDetailsRoutePage() {
    const router = useRouter();
    const bus = useEventsBus();
    const { $translate } = useI18n();
    const customerNotificationsStore = useCustomerNotificationsStore();
    const allMessages = toRef(customerNotificationsStore, 'allMessages');
    // eslint-disable-next-line max-len,@typescript-eslint/no-unsafe-enum-comparison
    const isNotRefMessage = computed(()=>router.getParam('source') !== CustomerNotificationMessageSource.REF);
    const message = computed(()=>getMessageByIdAndSource(allMessages.value, {
            id: String(router.getParam('id')) || '',
            source: router.getParam('source')
        }));
    // eslint-disable-next-line max-len
    const messageTime = computed(()=>message.value ? DateTime.formatTimeStamp(message.value.createdAt, TimeFormats.dayMonthYearHoursMinutes) : '');
    const chatButtonProperties = computed(()=>({
            kind: ButtonKind.BASE,
            rounded: true,
            label: $translate('WEB2_NOTIFICATION_ONLINE_CHAT').value,
            iconName: IconName.CHAT,
            isUppercase: false
        }));
    const ctaButtonProperties = computed(()=>({
            kind: ButtonKind.PRIMARY,
            label: message.value?.button?.text,
            fullWidth: message.value?.button?.fullWidth
        }));
    onMounted(async ()=>{
        if (!message.value) {
            await customerNotificationsStore.loadMessages();
            if (!message.value) {
                router.replace({
                    name: RouteName.CUSTOMER_NOTIFICATIONS
                });
                return;
            }
        }
        customerNotificationsStore.setChecked(message.value);
        if (!message.value.isRead) await customerNotificationsStore.setRead(message.value);
    });
    onBeforeUnmount(customerNotificationsStore.clearChecked);
    function onCtaClick() {
        if (message.value) customerNotificationsStore.setClicked(message.value);
        const link = message.value?.button?.link;
        if (link) {
            if (link.startsWith('http')) window.open(link);
            else router.push(link);
        }
    }
    function openChat() {
        bus.emit(BusEvent.OPEN_CHAT, {});
    }
    return {
        message,
        messageTime,
        ctaButtonProperties,
        isNotRefMessage,
        onCtaClick,
        chatButtonProperties,
        openChat
    };
}
