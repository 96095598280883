import { computed, onBeforeUnmount, onMounted, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { AlertIconName, IconName } from '@leon-hub/icons';
import { getJumbotronProps } from 'web/src/components/Jumbotron';
import { useCustomerNotificationsStore } from 'web/src/modules/customer-notifications/store';
import { getMessageByIdAndSource } from 'web/src/modules/customer-notifications/store/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useCustomerNotificationsRoutePage() {
    const router = useRouter();
    const { $translate } = useI18n();
    const customerNotificationStore = useCustomerNotificationsStore();
    const unreadCounter = toRef(customerNotificationStore, 'unreadTotalCounter');
    const allMessages = toRef(customerNotificationStore, 'allMessages');
    const checkedMessagesId = toRef(customerNotificationStore, 'checkedMessages');
    const isEditing = toRef(customerNotificationStore, 'isEditing');
    const isLoading = toRef(customerNotificationStore, 'isLoading');
    const jumbotronProperties = computed(()=>({
            ...getJumbotronProps({
                alertIcon: AlertIconName.NoNotifications,
                icon: IconName.NOTIFICATION_NO
            }),
            heading: $translate('WEB2_TITLE_CUSTOMER_NOTIFICATION').value,
            text: $translate('WEB2_MESSAGE_CUSTOMER_NOTIFICATION').value
        }));
    const unreadMessages = computed(()=>allMessages.value?.filter((item)=>!item.isRead) ?? []);
    const readMessages = computed(()=>allMessages.value?.filter((item)=>item.isRead) ?? []);
    watch(unreadCounter, (newCount, oldCount)=>{
        if (newCount > oldCount) customerNotificationStore.loadMessages();
    });
    onMounted(()=>{
        if (router.getParam('id')) {
            const lastMessage = getMessageByIdAndSource(allMessages.value, {
                id: Number(router.getParam('id')),
                // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
                source: router.getParam('source')
            });
            if (!lastMessage?.isRead) {
                router.push({
                    name: RouteName.CUSTOMER_NOTIFICATION_DETAILS,
                    params: {
                        id: router.getParam('id') || '',
                        source: router.getParam('source') || ''
                    }
                });
                return;
            }
        }
        customerNotificationStore.loadMessages();
    });
    onBeforeUnmount(customerNotificationStore.stopEditing);
    function onClose() {
        router.back();
    }
    function onClick(message) {
        router.push({
            name: RouteName.CUSTOMER_NOTIFICATION_DETAILS,
            params: {
                source: message.source,
                id: String(message.id)
            }
        });
    }
    function onClickCheckbox(message) {
        customerNotificationStore.toggleCheck(message);
    }
    return {
        allMessages,
        checkedMessagesId,
        isEditing,
        jumbotronProperties,
        unreadMessages,
        readMessages,
        isLoading,
        onClick,
        onClickCheckbox,
        onClose
    };
}
