import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { ButtonKind } from '@leon-hub/module-buttons';
import { useCustomerNotificationsRoutePage } from 'web/src/modules/customer-notifications/pages/CustomerNotificationsRoutePage/composables';
import CustomerNotificationsListBlock from 'web/src/modules/customer-notifications/components/CustomerNotificationsListBlock/CustomerNotificationsListBlock.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import SCustomerNotificationsListBlock from 'web/src/modules/customer-notifications/components/SCustomerNotificationsListBlock/SCustomerNotificationsListBlock.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerNotificationsRoutePage',
    setup (__props) {
        const { allMessages, checkedMessagesId, isEditing, jumbotronProperties, unreadMessages, readMessages, isLoading, onClick, onClickCheckbox, onClose } = useCustomerNotificationsRoutePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['customer-notifications'])
            }, [
                !_unref(allMessages) || _unref(isLoading) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                })) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _unref(allMessages).length > 0 ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _unref(unreadMessages).length > 0 ? (_openBlock(), _createBlock(CustomerNotificationsListBlock, {
                                key: 0,
                                title: _ctx.$t('WEB2_NOTIFICATIONS_UNREAD_MESSAGES'),
                                messages: _unref(unreadMessages),
                                "is-editing": _unref(isEditing),
                                "checked-ids": _unref(checkedMessagesId),
                                onClick: _unref(onClick),
                                onClickCheckbox: _unref(onClickCheckbox)
                            }, null, 8, [
                                "title",
                                "messages",
                                "is-editing",
                                "checked-ids",
                                "onClick",
                                "onClickCheckbox"
                            ])) : _createCommentVNode("", true),
                            _unref(readMessages).length > 0 ? (_openBlock(), _createBlock(CustomerNotificationsListBlock, {
                                key: 1,
                                title: _ctx.$t('WEB2_NOTIFICATIONS_READ_MESSAGES'),
                                messages: _unref(readMessages),
                                "is-editing": _unref(isEditing),
                                "checked-ids": _unref(checkedMessagesId),
                                "is-read": "",
                                onClick: _unref(onClick),
                                onClickCheckbox: _unref(onClickCheckbox)
                            }, null, 8, [
                                "title",
                                "messages",
                                "is-editing",
                                "checked-ids",
                                "onClick",
                                "onClickCheckbox"
                            ])) : _createCommentVNode("", true)
                        ], 64))
                    ], 64)) : (_openBlock(), _createBlock(_unref(VJumbotron), _mergeProps({
                        key: 1
                    }, _unref(jumbotronProperties), {
                        class: _ctx.$style['customer-notifications__empty']
                    }), _createSlots({
                        _: 2
                    }, [
                        void 0
                    ]), 1040, [
                        "class"
                    ]))
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomerNotificationsRoutePage'
                ]
            ]);
        };
    }
});
