import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createSlots as _createSlots, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "onClick"
];
import VPopper from 'web/src/components/Popper/VPopper/VPopper.vue';
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useCustomerNotificationsTopBarRouteComponent } from 'web/src/modules/customer-notifications/views/CustomerNotificationsTopBarRouteComponent/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerNotificationsTopBarRouteComponent',
    setup (__props) {
        const { slotName, isEditing, unreadCounter, badgeKind, canEdit, hasCheckedMessages, deleteButtonProperties, deleteCheckedConfirm, cancelButtonProperties, stopEditing, popperProperties, moreButtonProperties, startEditing, deleteAllConfirm } = useCustomerNotificationsTopBarRouteComponent();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                title: _unref(isEditing) ? '' : _ctx.$t('JS_OPERATOR_MESSAGES_TITLE'),
                badge: _unref(isEditing) ? '' : _unref(unreadCounter),
                "is-badge-counter": "",
                "hide-prefix": true,
                "badge-kind": _unref(badgeKind)
            }, _createSlots({
                _: 2
            }, [
                _unref(canEdit) ? {
                    name: _unref(slotName),
                    fn: _withCtx(()=>[
                            _unref(isEditing) ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _unref(hasCheckedMessages) ? (_openBlock(), _createBlock(VButton, _mergeProps({
                                    key: 0
                                }, _unref(deleteButtonProperties), {
                                    label: _ctx.$t('WEB2_DELETE_CHECKED_CUSTOMER_NOTIFICATION'),
                                    class: _ctx.$style['customer-notifications-top-bar__button'],
                                    onClick: _unref(deleteCheckedConfirm)
                                }), null, 16, [
                                    "label",
                                    "class",
                                    "onClick"
                                ])) : (_openBlock(), _createBlock(VButton, _mergeProps({
                                    key: 1
                                }, _unref(cancelButtonProperties), {
                                    label: _ctx.$t('WEB2_CANCEL_EDIT_CUSTOMER_NOTIFICATION'),
                                    class: _ctx.$style['customer-notifications-top-bar__button'],
                                    onClick: _unref(stopEditing)
                                }), null, 16, [
                                    "label",
                                    "class",
                                    "onClick"
                                ]))
                            ], 64)) : (_openBlock(), _createBlock(VPopper, _normalizeProps(_mergeProps({
                                key: 1
                            }, _unref(popperProperties))), {
                                reference: _withCtx(()=>[
                                        _createVNode(VButton, _normalizeProps(_guardReactiveProps(_unref(moreButtonProperties))), null, 16)
                                    ]),
                                default: _withCtx((param)=>{
                                    let { hide } = param;
                                    return [
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['customer-notifications-top-bar__dropdown-list']),
                                            onClick: hide
                                        }, [
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['customer-notifications-top-bar__dropdown-list-item']),
                                                onClick: _cache[0] || (_cache[0] = //@ts-ignore
                                                function() {
                                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                        args[_key] = arguments[_key];
                                                    }
                                                    return _unref(startEditing) && _unref(startEditing)(...args);
                                                })
                                            }, _toDisplayString(_ctx.$t('WEB2_START_EDIT_CUSTOMER_NOTIFICATION')), 3),
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['customer-notifications-top-bar__dropdown-list-item']),
                                                onClick: _cache[1] || (_cache[1] = //@ts-ignore
                                                function() {
                                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                        args[_key] = arguments[_key];
                                                    }
                                                    return _unref(deleteAllConfirm) && _unref(deleteAllConfirm)(...args);
                                                })
                                            }, _toDisplayString(_ctx.$t('WEB2_DELETE_ALL_CUSTOMER_NOTIFICATION')), 3)
                                        ], 10, _hoisted_1)
                                    ];
                                }),
                                _: 1
                            }, 16))
                        ]),
                    key: "0"
                } : void 0
            ]), 1032, [
                "title",
                "badge",
                "hide-prefix",
                "badge-kind"
            ])), [
                [
                    _directive_auto_id,
                    'CustomerNotificationsTopBarRouteComponent'
                ]
            ]);
        };
    }
});
