import { computed, onBeforeUnmount, ref, toRef } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import { PopperPosition } from 'web/src/components/Popper/VPopper/enums';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useCustomerNotificationsUnreadCounter } from 'web/src/modules/customer-notifications/composables';
import { useCustomerNotificationsStore } from 'web/src/modules/customer-notifications/store';
import LanguageMicroSettings from 'web/src/utils/LanguageMicroSettings';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useCustomerNotificationsTopBarRouteComponent() {
    let deleteModalId = null;
    const { $translate } = useI18n();
    const { closeDialog, showDialog } = useDialogs();
    const { unreadCounter } = useCustomerNotificationsUnreadCounter();
    const customerNotificationStore = useCustomerNotificationsStore();
    const isEditing = toRef(customerNotificationStore, 'isEditing');
    const checkedMessagesRef = toRef(customerNotificationStore, 'checkedMessages');
    const allMessagesRef = toRef(customerNotificationStore, 'allMessages');
    const locale = toRef(useI18nStore(), 'locale');
    const badgeKind = BadgeKind.DEFAULT;
    const popperProperties = {
        position: PopperPosition.BOTTOM_END,
        hasOffset: false,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [
                        24,
                        8
                    ]
                }
            }
        ]
    };
    const moreButtonProperties = {
        kind: ButtonKind.TRANSPARENT,
        iconName: IconName.MORE,
        iconSize: IconSize.SIZE_24
    };
    const cancelButtonProperties = {
        kind: ButtonKind.BASE,
        height: ButtonHeight.SMALL
    };
    const deleteButtonProperties = {
        kind: ButtonKind.PRIMARY,
        height: ButtonHeight.SMALL
    };
    const slotName = 'prefix';
    const hasCheckedMessages = computed(()=>checkedMessagesRef.value.length > 0);
    const canEdit = computed(()=>!!allMessagesRef.value?.length);
    onBeforeUnmount(()=>{
        if (deleteModalId) closeDialog(deleteModalId);
    });
    function startEditing() {
        customerNotificationStore.setEditing(true);
    }
    function stopEditing() {
        customerNotificationStore.stopEditing();
    }
    async function deleteAll() {
        stopEditing();
        const failedMessages = await customerNotificationStore.deleteAll();
        showDeleteErrorModal(failedMessages.length);
    }
    async function deleteChecked() {
        deleteModalId = null;
        stopEditing();
        const failedMessages = await customerNotificationStore.deleteChecked();
        showDeleteErrorModal(failedMessages.length);
    }
    function showDeleteErrorModal(failedMessagesCount) {
        const failedMessagesCountRef = ref({
            count: String(failedMessagesCount)
        });
        if (failedMessagesCount > 0) {
            const confirmMessage = LanguageMicroSettings.plural([
                $translate('WEB2_MODAL_MSG_DELETE_ERROR_CUSTOMER_NOTIFICATION_ONE', failedMessagesCountRef).value,
                $translate('WEB2_MODAL_MSG_DELETE_ERROR_CUSTOMER_NOTIFICATION_TWO', failedMessagesCountRef).value,
                $translate('WEB2_MODAL_MSG_DELETE_ERROR_CUSTOMER_NOTIFICATION_MANY', failedMessagesCountRef).value
            ], failedMessagesCount, locale.value);
            const { id } = showDialog({
                presetName: PresetName.ALERT_ERROR,
                options: {
                    confirmMessage,
                    dataTestId: 'delete-notification-error'
                }
            });
            deleteModalId = id;
        }
    }
    function deleteAllConfirm() {
        const { subscribe, id } = showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                title: $translate('WEB2_MODAL_TITLE_DELETE_ALL_CUSTOMER_NOTIFICATION').value,
                confirmMessage: $translate('WEB2_MODAL_MSG_DELETE_ALL_CUSTOMER_NOTIFICATION').value,
                buttons: [
                    {
                        label: $translate('WEB2_MODAL_DELETE_BUTTON_CUSTOMER_NOTIFICATION').value
                    },
                    {
                        label: $translate('WEB2_MODAL_CANCEL_BUTTON_CUSTOMER_NOTIFICATION').value
                    }
                ],
                dataTestId: 'delete-all-notification-confirm'
            }
        });
        deleteModalId = id;
        subscribe({
            [DialogAction.CONFIRM]: ()=>deleteAll()
        });
    }
    function deleteCheckedConfirm() {
        const { subscribe, id } = showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                title: $translate('WEB2_MODAL_TITLE_DELETE_CHECKED_CUSTOMER_NOTIFICATION').value,
                confirmMessage: $translate('WEB2_MODAL_MSG_DELETE_CHECKED_CUSTOMER_NOTIFICATION').value,
                buttons: [
                    {
                        label: $translate('WEB2_MODAL_DELETE_BUTTON_CUSTOMER_NOTIFICATION').value
                    },
                    {
                        label: $translate('WEB2_MODAL_CANCEL_BUTTON_CUSTOMER_NOTIFICATION').value
                    }
                ],
                dataTestId: 'delete-checked-notification-confirm'
            }
        });
        deleteModalId = id;
        subscribe({
            [DialogAction.CONFIRM]: ()=>deleteChecked()
        });
    }
    return {
        slotName,
        isEditing,
        unreadCounter,
        badgeKind,
        canEdit,
        hasCheckedMessages,
        deleteButtonProperties,
        deleteCheckedConfirm,
        cancelButtonProperties,
        stopEditing,
        popperProperties,
        moreButtonProperties,
        startEditing,
        deleteAllConfirm
    };
}
