import { useRouter } from 'vue-router';
import { computed, toRef } from 'vue';
import RouteName from '@leon-hub/routing-config-names';
import { CustomerNotificationMessageSource } from 'web/src/modules/customer-notifications/enums';
import useCustomerNotificationsStore from '../../../store/useCustomerNotificationsStore';
export default function useCustomerRefNotification() {
    const router = useRouter();
    const notificationsStore = useCustomerNotificationsStore();
    const refFrontNotificationMessage = toRef(notificationsStore, 'refFrontNotificationMessage');
    const message = computed(()=>refFrontNotificationMessage.value.find((item)=>item.source === CustomerNotificationMessageSource.REF));
    const data = computed(()=>message.value?.payload ?? {});
    const handleClick = ()=>{
        router.push({
            name: RouteName.DEPOSITS
        });
    };
    return {
        message,
        data,
        handleClick
    };
}
