import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, withCtx as _withCtx } from "vue";
import VListItemExtended from 'web/src/components/List/VListItemExtended/VListItemExtended.vue';
import SubHeader from 'web/src/components/SubHeader/VSubHeader/VSubHeader.vue';
import VList from 'web/src/components/List/VList/VList.vue';
import { useCustomerNotificationsListBlock } from 'web/src/modules/customer-notifications/components/CustomerNotificationsListBlock/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerNotificationsListBlock',
    props: {
        title: {
            default: ''
        },
        messages: {
            default: ()=>[]
        },
        checkedIds: {
            default: ()=>[]
        },
        isRead: {
            type: Boolean
        },
        isEditing: {
            type: Boolean
        }
    },
    emits: [
        "click",
        "click-checkbox"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { listItemKind, getMessageTime, isChecked, emitClick, emitClickCheckbox } = useCustomerNotificationsListBlock(props, emits);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createVNode(SubHeader, {
                    text: _ctx.title
                }, null, 8, [
                    "text"
                ]),
                _createVNode(VList, null, {
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message)=>_withDirectives((_openBlock(), _createBlock(VListItemExtended, {
                                    key: message.id,
                                    title: message.title,
                                    "sub-title-first": _unref(getMessageTime)(message),
                                    "is-disabled": _ctx.isRead,
                                    "is-checked": _unref(isChecked)(message),
                                    kind: _unref(listItemKind),
                                    onClick: ($event)=>_unref(emitClick)(message),
                                    onClickCheckbox: ($event)=>_unref(emitClickCheckbox)(message)
                                }, null, 8, [
                                    "title",
                                    "sub-title-first",
                                    "is-disabled",
                                    "is-checked",
                                    "kind",
                                    "onClick",
                                    "onClickCheckbox"
                                ])), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: `list-item-${_ctx.isRead ? 'read' : 'unread'}`
                                        }
                                    ]
                                ])), 128))
                        ]),
                    _: 1
                })
            ])), [
                [
                    _directive_auto_id,
                    'CustomerNotificationsListBlock'
                ]
            ]);
        };
    }
});
