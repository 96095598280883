import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import ReferralProgramRegistrationBody from // eslint-disable-next-line max-len
'web/src/modules/referral-program/components/ReferralProgramRegistrationBody/ReferralProgramRegistrationBody.vue';
import { useCustomerRefNotification } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerRefNotification',
    setup (__props) {
        const { data, handleClick } = useCustomerRefNotification();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(data) ? _withDirectives((_openBlock(), _createBlock(ReferralProgramRegistrationBody, _mergeProps({
                key: 0
            }, _unref(data), {
                onClick: _unref(handleClick)
            }), null, 16, [
                "onClick"
            ])), [
                [
                    _directive_auto_id,
                    'CustomerRefNotification'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
