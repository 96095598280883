import { computed } from 'vue';
import { ListItemExtendedKind } from 'web/src/components/List/VListItemExtended/enums';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
export default function useCustomerNotificationsListBlock(props, emits) {
    // eslint-disable-next-line max-len
    const listItemKind = computed(()=>props.isEditing ? ListItemExtendedKind.CHECKBOX : ListItemExtendedKind.NOTIFICATION);
    function getMessageTime(message) {
        return DateTime.formatTimeStamp(message.createdAt, TimeFormats.dayMonthYearHoursMinutes);
    }
    function isChecked(message) {
        return props.checkedIds.some((item)=>item.id === message.id && item.source === message.source);
    }
    function emitClick(message) {
        emits('click', message);
    }
    function emitClickCheckbox(message) {
        emits('click-checkbox', message);
    }
    return {
        listItemKind,
        getMessageTime,
        isChecked,
        emitClick,
        emitClickCheckbox
    };
}
