import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import { useCustomerNotificationDetailsTopBarRouteComponent } from 'web/src/modules/customer-notifications/views/CustomerNotificationDetailsTopBarRouteComponent/composables';
import SNavigationButton from 'web/src/components/Button/SNavigationButton/SNavigationButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerNotificationDetailsTopBarRouteComponent',
    setup (__props) {
        const { isDeleteInProgress, deleteButtonProperties, deleteConfirm } = useCustomerNotificationDetailsTopBarRouteComponent();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                title: " "
            }, {
                suffix: _withCtx((param)=>{
                    let { goBackOrClose } = param;
                    return [
                        (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _unref(isDeleteInProgress) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(VButton, _mergeProps({
                                key: 0
                            }, _unref(deleteButtonProperties), {
                                onClick: ($event)=>_unref(deleteConfirm)(goBackOrClose)
                            }), null, 16, [
                                "onClick"
                            ]))
                        ], 64))
                    ];
                }),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'CustomerNotificationDetailsTopBarRouteComponent'
                ]
            ]);
        };
    }
});
