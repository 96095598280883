import { ref } from 'vue';
import { AlertIconName, IconName, IconSize } from '@leon-hub/icons';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useCustomerNotificationsStore } from 'web/src/modules/customer-notifications/store';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { getImageOrIcon } from 'web/src/modules/icons';
export default function useCustomerNotificationDetailsTopBarRouteComponent() {
    const isDeleteInProgress = ref(false);
    const { $translate } = useI18n();
    const { showDialog } = useDialogs();
    const customerNotificationStore = useCustomerNotificationsStore();
    const deleteButtonProperties = {
        kind: ButtonKind.NAV,
        iconName: IconName.BIN,
        iconSize: IconSize.SIZE_24
    };
    async function deleteNotification(callback) {
        isDeleteInProgress.value = true;
        const failedMessages = await customerNotificationStore.deleteChecked();
        isDeleteInProgress.value = false;
        if (failedMessages.length) {
            const params = ref({
                count: '1'
            });
            customerNotificationStore.setChecked(failedMessages[0]);
            showDialog({
                presetName: PresetName.ALERT_ERROR,
                options: {
                    ...getImageOrIcon({
                        alertIcon: AlertIconName.QuestionMark
                    }),
                    confirmMessage: $translate('WEB2_MODAL_MSG_DELETE_ERROR_CUSTOMER_NOTIFICATION_ONE', params).value,
                    dataTestId: 'delete-notification-error'
                }
            });
        } else callback();
    }
    function deleteConfirm(callback) {
        const { subscribe } = showDialog({
            presetName: PresetName.CONFIRM,
            options: {
                title: $translate('WEB2_MODAL_TITLE_DELETE_DETAILS_CUSTOMER_NOTIFICATION').value,
                confirmMessage: $translate('WEB2_MODAL_MSG_DELETE_DETAILS_CUSTOMER_NOTIFICATION').value,
                image: getImageOrIcon({
                    alertIcon: AlertIconName.QuestionMark
                }).image,
                buttons: [
                    {
                        label: $translate('WEB2_MODAL_DELETE_BUTTON_CUSTOMER_NOTIFICATION').value
                    },
                    {
                        label: $translate('WEB2_MODAL_CANCEL_BUTTON_CUSTOMER_NOTIFICATION').value
                    }
                ],
                dataTestId: 'delete-notification-confirm'
            }
        });
        subscribe({
            [DialogAction.CONFIRM]: ()=>{
                deleteNotification(callback);
            }
        });
    }
    return {
        isDeleteInProgress,
        deleteButtonProperties,
        deleteConfirm
    };
}
